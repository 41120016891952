import React from "react"
import { Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import Layout from "../components/layout"

const NotFoundPage = () => (
    <Layout>
        <section className="section">
            <Container>
                <div className="has-padding-large has-bg-white">
                    <h1 className="heading">Deze pagina is momenteel niet beschikbaar.</h1>
                    <section className="section-intro wysiwyg">
                        Ga naar de <Link to="/">homepage</Link> om een van onze andere inspiratie-pagina’s te bekijken.
                    </section>
                </div>
            </Container>
        </section>
        
    </Layout>
)

export default NotFoundPage
